document.addEventListener("DOMContentLoaded", appMain);

function appMain() {
    formBtn();
    maskPhone();
    sendmail();
}

let activeClass = 'is-active';

// состояние кнопки при фокусе инпута
function formBtn() {
  const forms = document.querySelectorAll('.modal-contactus__form');

  if (forms.length) {
      forms.forEach(function (form) {
          const formInput = form.querySelector('.form__input');
          const formButton = form.querySelector('.form__btn_main');
          const formCursor = form.querySelector('.form__container_anim');

          formInput.addEventListener('focus', function () {
              console.log('focus');
              formCursor.classList.add(activeClass);
              formInput.addEventListener('input', function () {
                  if (formInput.value.trim() !== '') {
                      formButton.classList.add(activeClass);
                      formCursor.classList.add(activeClass);
                      formButton.innerHTML = 'Отправить';
                  } else {
                      formButton.classList.remove(activeClass);
                      formCursor.classList.remove(activeClass);
                      formButton.innerHTML = 'Оставить заявку';
                  }
              });

              formInput.addEventListener('blur', function () {
                  console.log('blur');
                  if (formInput.value.trim() === '') {
                      formButton.classList.remove(activeClass);
                      formCursor.classList.remove(activeClass);
                      formButton.innerHTML = 'Оставить заявку';
                  }
              });
          });

          // Добавляем обработчик для события mouseenter
          formInput.addEventListener('mouseenter', function () {
              formButton.classList.add(activeClass);
              // formCursor.classList.add(activeClass);
              formButton.innerHTML = 'Отправить';
          });

          // Добавляем обработчик для события mouseleave
          formInput.addEventListener('mouseleave', function () {
              // Проверяем, не остался ли инпут в фокусе
              // if (!formInput.matches(':focus')) {
              //     formButton.classList.remove(activeClass);
              //     formCursor.classList.remove(activeClass);
              //     formButton.innerHTML = 'Оставить заявку';
              // } else 
              if (formInput.value.trim() !== '') {
                formButton.classList.add(activeClass);
                formCursor.classList.add(activeClass);
                formButton.innerHTML = 'Отправить';
              } else {
                  formButton.classList.remove(activeClass);
                  formCursor.classList.remove(activeClass);
                  formButton.innerHTML = 'Оставить заявку';
              }
          });
      });
  }
}



// маска телефона
function maskPhone() {
    var eventCallback = function (e) {
        var el = e.target,
            clearVal = el.dataset.phoneClear,
            pattern = el.dataset.phonePattern,
            matrix_def = "+7 (___) ___-__-__",
            matrix = pattern ? pattern : matrix_def,
            i = 0,
            def = matrix.replace(/\D/g, ""),
            val = e.target.value.replace(/\D/g, "");
  
        // запрещаем ввод более 8 одинаковых цифр
        function hasMoreThanEightSameDigits(value) {
            var digitCounts = Array(10).fill(0);
            for (var digit of value) {
                digitCounts[digit]++;
                if (digitCounts[digit] > 8) {
                    return true;
                }
            }
            return false;
        }
  
        if (hasMoreThanEightSameDigits(val)) {
            e.target.value = e.target.value.slice(0, -1);
            return;
        }
  
        if (clearVal !== 'false' && e.type === 'blur') {
            if (val.length < matrix.match(/([\_\d])/g).length) {
                e.target.value = '';
                return;
            }
        }
        if (def.length >= val.length) val = def;
        e.target.value = matrix.replace(/./g, function (a) {
            return /[_\d]/.test(a) && i < val.length ? val.charAt(i++) : i >= val.length ? "" : a;
        });
    }
  
    var phone_inputs = document.querySelectorAll('input[name="phone"]');
    for (let elem of phone_inputs) {
        for (let ev of ['input', 'blur', 'focus']) {
            elem.addEventListener(ev, eventCallback);
        }
    }
}


function checkValidation(validationOptions = []) {
  let isValid = true;

  validationOptions.forEach((opt) => {
    const isVal = opt.cb(opt.el);
    if (isVal) return;

    isValid = false;
    setMessage(opt.el, opt.message, opt.addEvent);
  });

  function setMessage(el, message = "Это поле обязательно", event = false) {
    const messageContElement = el
      .closest(".warcont")
      .querySelector(".inputbase__valid");
    if (!messageContElement) return;

    const messageTextElement = messageContElement.querySelector(
      ".inputbase__content"
    );

    messageContElement.classList.add("active");
    messageTextElement.textContent = message;

    if (event)
      el.addEventListener(event === true ? "input" : event, () => {
        messageContElement.classList.remove("active");
        messageTextElement.textContent = "";
      });
  }

  return isValid;
}
  
function sendmail() {
    const forms = document.querySelectorAll("form");
    // console.log(forms);
    forms.forEach((form) => {
      form.addEventListener("submit", async (e) => {
        e.preventDefault();
  
        // валидация
        const phone = form.phone;
        // const soglasie = form.soglasie;
        const formButton = form.querySelector('.form__btn_main');
        // if (!phone || !soglasie) {
        // if (!soglasie) {
        //   alert("Что-то пошло не так, попробуйте чуть позже!");
        //   return;
        // }
  
        const isValid = checkValidation([
          // {
          //   el: form.phone,
          //   cb: (el) => el.value.length === 18,
          //   message: "Поле заполнено неверно",
          //   addEvent: true,
          // },
          // {
          //   el: form.soglasie,
          //   cb: (el) => el.checked,
          //   message: "Отметьте согласие",
          //   addEvent: "change",
          // },
        ]);
  
        if (!isValid) return;
        // валидация
        
  
  
        const sendrequest = async (fd, fd2) => {
          const response = await fetch("/wp-admin/admin-ajax.php", {
            method: "POST",
            body: fd,
          });
          fetch(
            "https://script.google.com/macros/s/AKfycbxX60xikG2JiSuUtWtjde6MOaWtLYA7p6SfhJGDuSFYUhRTB6eTN47IKUda4eOmJWDJ0A/exec",
            {
              method: "POST",
              body: fd2,
            }
          );
          return response.ok;
        };
  
        form.classList.add("pending");
  
        const fd = new FormData(form);
        fd.append("url", location.href);
  
        const fd2 = new FormData();
        fd2.append("Сайт", location.href);
        fd2.append("Телефон", phone.value);
        fd2.append("Имя", form.name || "---");
        fd2.append("Тема", form.theme ? form.theme.value : "Обратный звонок");
        const params = new URLSearchParams(location.search);
        [...params.entries()].forEach((par) => {
          fd2.append(par[0], par[1]);
        });
        const isok = await sendrequest(fd, fd2);
  
        form.classList.remove("pending");
  
        if (isok) {
          // state.modalContactUs.close();
          // state.modalCreateOrder.close();
          // state.modaTy.open();
          console.log('форма отправлена');
          formButton.classList.add("sending");
          setTimeout(() => { 
            formButton.classList.remove("sending");
            form.reset();
          }, 5000);
          // ym(window.code, "reachGoal", "lead");
        } else {
          alert("Ошибка. Что-то пошло не так, попробуйте чуть позже!");
        }
      });
    });
  }